import React, { useCallback, useContext, useEffect, useState } from 'react';
import { EditGroup } from './EditGroup';
import useForm from './../../hooks/useForm';
import { deleteCompany, fetchCompany, updateCompany } from './../../actions/company.action';
import Swal from 'sweetalert2';
import SectionTitle from '../_ui/SectionTitle/SectionTitle';
import { FaTimesCircle } from "react-icons/fa";
import { CompanyContext } from '../../reducers/CompanyContext';
import { types } from '../../types/types';
import ActionButton from '../_ui/ActionButton/ActionButton';
import Modal from 'react-bootstrap/Modal';
export const UpdateCompany = () => {
	const { dispatchCompany } = useContext(CompanyContext);
	const { values: myCompany, setValues, handleInputChange, handleFileChange } = useForm();
	const { values: category, handleInputChange: handleInputChangeCat } = useForm();
	const getMyCompany = useCallback(async () => {
		let company = await fetchCompany();
		setValues(company[0]);
	}, [setValues]);

	useEffect(() => {
		getMyCompany();
	}, [getMyCompany]);

	const handleDelete = async () => {
		await deleteCompany(myCompany?._id);

	};
	const askIfDelete = () => {
		Swal.fire({
			title: '¿Seguro?',
			text: 'Si borras esto, no habrá datos de empresa',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '¡Borrar Empresa!',
			cancelButtonText: '¡Uy, no!',
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete();
				Swal.fire('¡Empresa borrada!', 'Esta empresa se marchó para no volver', 'success');
			}
		});
	};

	const deleteFeature = (idx) => {
		const categoriesCopy = [...myCompany?.categories];
		categoriesCopy.splice(idx, 1);
		setValues(company => ({
			...company,
			categories: categoriesCopy,
		}));
	};


	const deleteField = async (property) => {
		setValues(company => ({
			...company,
			[property]: '',
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	const saveChanges = async () => {
		await updateCompany(myCompany._id, myCompany);
		dispatchCompany({ type: types.updateCompany, payload: myCompany });
	};
	//Categroias
	const [showAddCat, setShowAddCat] = useState(false);
	const handleCloseCat = () => setShowAddCat(false);
	const handleOpenCat = () => setShowAddCat(true);
	const handleAddCategory = (e) => {
		e.preventDefault();
		if (myCompany?.categories.length === 0) {
			setValues(company => ({
				...company,
				categories: [category],
			}));

		} else {
			if (!myCompany?.categories.some(elm => elm.name === category.name)) {
				setValues(company => ({
					...company,
					categories: [...myCompany?.categories, category],
				}));
			}
		}
		setShowAddCat(false);

	};
	return (
		<>
			<SectionTitle
				image={ process.env.REACT_APP_COMPANY_IMAGE }
				title="Datos de empresa"
				instructions="Aquí puedes editar los datos de tu empresa :)"
			/>
			<div className="btn-group">

				<button className='my-btn mini' onClick={ saveChanges }>
					Guardar Cambios
				</button>
				<button className='my-btn danger mini' onClick={ askIfDelete }>
					Borrar esta empresa
				</button>
			</div>

			<div className='editing'>
				<EditGroup deleteField={ deleteField } nameValue={ 'name' } inputType={ 'text' } editLabel={ 'Nombre de empresa' } editAction={ handleInputChange } editValue={ myCompany?.name } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'address' } inputType={ 'text' } editLabel={ 'Dirección' } editAction={ handleInputChange } editValue={ myCompany?.address } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'phone' } inputType={ 'text' } editLabel={ 'Teléfono principal' } editAction={ handleInputChange } editValue={ myCompany?.phone } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'mainEmail' } inputType={ 'email' } editLabel={ 'Email principal' } editAction={ handleInputChange } editValue={ myCompany?.mainEmail } submitEdit={ handleSubmit } />
				<EditGroup
					deleteField={ deleteField }
					nameValue={ 'mainLogo' }
					imageEdit={ true }
					inputType={ 'file' }
					editLabel={ 'Logo Principal' }
					editAction={ handleFileChange }
					editValue={ myCompany?.mainLogo }
					submitEdit={ handleSubmit }
				/>
				<EditGroup
					deleteField={ deleteField }
					nameValue={ 'secondaryLogo' }
					imageEdit={ true }
					inputType={ 'file' }
					editLabel={ 'Logo B/N' }
					editAction={ handleFileChange }
					editValue={ myCompany?.secondaryLogo }
					submitEdit={ handleSubmit }
				/>
				<EditGroup deleteField={ deleteField } nameValue={ 'twitter' } inputType={ 'text' } editLabel={ 'Twitter Url' } editAction={ handleInputChange } editValue={ myCompany?.twitter } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'facebook' } inputType={ 'text' } editLabel={ 'Facebook url' } editAction={ handleInputChange } editValue={ myCompany?.facebook } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'instagram' } inputType={ 'text' } editLabel={ 'Instagram Url' } editAction={ handleInputChange } editValue={ myCompany?.instagram } submitEdit={ handleSubmit } />
				<EditGroup deleteField={ deleteField } nameValue={ 'linkedin' } inputType={ 'text' } editLabel={ 'Linkedin Url' } editAction={ handleInputChange } editValue={ myCompany?.linkedin } submitEdit={ handleSubmit } />
				<div className='edit-group categories'>
					<div className="title-btn">
						<label>Categorías de platos:</label>
						<ActionButton type={ 'add' } action={ handleOpenCat } />
					</div>
					<div className='features'>
						{ myCompany?.categories?.map((ft, idx) => (
							<div className='each-feat' key={ ft + idx }>
								Categoría: { ft.name }, Orden: { ft.order }
								<FaTimesCircle onClick={ () => deleteFeature(idx) } />
							</div>
						)) }
					</div>
					<Modal centered show={ showAddCat } onHide={ handleCloseCat }>
						<Modal.Header closeButton>
							<h4>Añadir Categoría de Carta</h4>
						</Modal.Header>
						<Modal.Body>
							<form className='edit-form' onSubmit={ handleAddCategory }>
								<input className='form-input' type="number" name="order" onChange={ handleInputChangeCat } placeholder='Orden de la categoría' />
								<input className='form-input' type="text" name='name' onChange={ handleInputChangeCat } placeholder='Nombre de la categoría' />
								<button className='my-btn mini right' type='submit'>Añadir</button>
							</form>
						</Modal.Body>
					</Modal>

				</div>
			</div>
		</>
	);
};
