import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import SectionTitle from '../../_ui/SectionTitle/SectionTitle';
import Modal from 'react-bootstrap/Modal';
import useForm from '../../../hooks/useForm';
import { CompanyContext } from '../../../reducers/CompanyContext';
import Select from 'react-select';
import { allDishes, createDish, deleteDishById, translateDishById, updateDishById } from '../../../actions/dish.action';
import DishElement from '../../_ui/DishElement/DishElement';

const Carta = () => {
    const isMounted = useRef(true);
    const [dishes, setDishes] = useState([]);
    const { company } = useContext(CompanyContext);
    const [checked, setChecked] = useState(false);
    const [language, setLanguage] = useState('');

    const allMyPosts = async () => {
        const dishes = await allDishes();
        setDishes(dishes);
    };

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            allMyPosts();
            setChecked(false);
        }
        return () => {
            isMounted.current = false;
        };
    });

    const handleDeletePost = async (id) => {
        await deleteDishById(id);
        await allMyPosts();
    };

    const askIfDelete = (id) => {
        Swal.fire({
            title: '¿Seguro?',
            text: 'Si borras esto, el plato desaparece',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Borrar plato!',
            cancelButtonText: '¡Uy, no!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeletePost(id);
                Swal.fire('¡Plato borrado!', 'Este plato se marchó para no volver', 'success');
            }
        });
    };

    const [showModal, setShowModal] = useState(false);
    const handleCloseCat = () => setShowModal(false);
    const handleOpenCat = (isEd) => {
        !isEd && setValues({});
        !isEd && setAlergenos([]);
        setIsEdit(isEd);
        setShowModal(true);
    };

    const { values: dish, setValues, handleInputChange } = useForm();
    const options = [
        { value: 'moluscos', label: 'Moluscos' },
        { value: 'lacteos', label: 'Lacteos' },
        { value: 'cascara', label: 'Cascara' },
        { value: 'gluten', label: 'Gluten' },
        { value: 'huevos', label: 'Huevos' },
        { value: 'soja', label: 'Soja' },
        { value: 'crustaceos', label: 'Crustaceos' },
        { value: 'sesamo', label: 'Sesamo' },
        { value: 'pescado', label: 'Pescado' },
        { value: 'cacahuetes', label: 'Cacahuetes' },
        { value: 'altramuces', label: 'Altramuces' },
        { value: 'mostaza', label: 'Mostaza' },
        { value: 'apio', label: 'Apio' },
    ];
    const optionsLangs = [
        { value: 'ES', label: 'Español' },
        { value: 'EN', label: 'Inglés' },
    ];

    const [alergenos, setAlergenos] = useState([]);

    const handleInputLang = (lang) => {
        setLanguage(lang);
    };

    const handleInput = (newValue) => {
        setAlergenos(newValue);
    };

    const handleSlider = () => {
        setChecked(c => !c);
        dish.active = checked;
    };

    const handleAddDish = async (e) => {
        e.preventDefault();
        dish.alergenos = alergenos.map(elm => elm.value);
        dish.price = Number(dish.price);
        dish.active = checked;
        dish.language = language.value;
        let is = isEdit ? await updateDishById(dish._id, dish) : await createDish(dish);
        if (is) {
            await allMyPosts();
            handleCloseCat();
            setIsEdit(false);
        }
    };

    const [isEdit, setIsEdit] = useState(false);

    const updateDish = (id) => {
        setAlergenos([]);
        let selected = dishes.find(dish => dish._id === id);
        setLanguage(optionsLangs.find(elm => elm.value === selected.language));
        selected?.alergenos.length > 0 && selected?.alergenos.forEach(elm => setAlergenos(p => [...p, { value: elm, label: elm }]));
        setChecked(selected?.active);
        setValues(selected);
        handleOpenCat(true);
    };

    const translateDish = async (id) => {
        await translateDishById(id);
        await allMyPosts();
    };

    return (
        <div id="dishes-screen">
            <SectionTitle
                image='https://res.cloudinary.com/bratic-app/image/upload/v1621695842/web/blog_vadvsn.svg'
                title="Carta"
                instructions={ `En la web se mostrarán los platos que tengan el estado activo.` }
            />

            <button className="my-btn primary mini" onClick={ () => handleOpenCat(false) }>Añadir plato</button>

            { dishes?.length > 0 ? (
                <section className='all-posts'>
                    { dishes?.map((elm) => (
                        <DishElement askIfDelete={ askIfDelete } updateDish={ updateDish } post={ elm } key={ elm._id } translateDish={ translateDish } />
                    )) }
                </section>
            ) : (
                <article className='empty-posts'>No tienes platos creados</article>
            ) }

            <Modal centered show={ showModal } onHide={ handleCloseCat }>
                <Modal.Header closeButton>
                    <h4>{ !isEdit ? 'Añadir Plato' : 'Editar Plato en ' + (dish.language === 'ES' ? '🇪🇸' : '🇬🇧') }</h4>
                </Modal.Header>
                <Modal.Body>
                    {
                        isEdit ? (

                            <>
                                <form className='edit-form' onSubmit={ handleAddDish }>
                                    <input className='form-input' type="text" name='dish' onChange={ handleInputChange } value={ dish.dish } />
                                    <input className='form-input' type="text" name='description' onChange={ handleInputChange } value={ dish.description } placeholder='Descripción del plato' />
                                    <input className='form-input' type="number" name='price' onChange={ handleInputChange } value={ dish.price } />
                                    {
                                        company?.categories?.length > 0 && (
                                            <select onChange={ handleInputChange } name='category' required>
                                                <option value={ dish.category } defaultValue>
                                                    { dish.category }
                                                </option>
                                                {
                                                    company?.categories?.map(cat => (cat.name !== dish.category && <option key={ `company-cat-${cat.order}-${cat.name}` } value={ cat.name }>{ cat.name }</option>))
                                                }
                                            </select>
                                        )
                                    }
                                    <Select value={ language } placeholder="Idioma" className='special-select' onChange={ handleInputLang } name="language" options={ optionsLangs } />
                                    <Select value={ alergenos } placeholder="Alérgenos" className='special-select' onChange={ handleInput } name="alergenos" isMulti options={ options } />
                                    <div className="toggle">
                                        <label className="switch">
                                            <input type="checkbox" onChange={ handleSlider } checked={ checked } />
                                            <span className="slider round"></span>
                                        </label>
                                        <span>{ checked ? 'Plato visible' : 'Plato Oculto' }</span>
                                    </div>
                                    <button className='my-btn mini right' type='submit'>Editar</button>
                                </form>
                            </>

                        ) : (

                            <form className='edit-form' onSubmit={ handleAddDish }>
                                <input required className='form-input' type="text" name='dish' onChange={ handleInputChange } placeholder='Plato' />
                                <input className='form-input' type="text" name='description' onChange={ handleInputChange } placeholder='Descripción del plato' />
                                <input required className='form-input' type="number" name='price' onChange={ handleInputChange } placeholder='Precio' />
                                {
                                    company?.categories?.length > 0 && (
                                        <select onChange={ handleInputChange } name='category' required>
                                            <option value='' defaultValue>
                                                Categoría del plato
                                            </option>
                                            {
                                                company?.categories?.map(cat => (<option key={ `company-cat-${cat.order}-${cat.name}` } value={ cat.name }>{ cat.name }</option>))
                                            }
                                        </select>
                                    )
                                }
                                <Select placeholder="Idioma" className='special-select' onChange={ handleInputLang } name="language" options={ optionsLangs } />
                                <Select placeholder="Alérgenos" className='special-select' onChange={ handleInput } name="alergenos" isMulti options={ options } />
                                <div className="toggle">
                                    <label className="switch">
                                        <input type="checkbox" onChange={ handleSlider } checked={ checked } />
                                        <span className="slider round"></span>
                                    </label>
                                    <span>{ checked ? 'Plato visible' : 'Plato Oculto' }</span>
                                </div>
                                <button className='my-btn mini right' type='submit'>Añadir</button>
                            </form>
                        )
                    }
                </Modal.Body>

            </Modal>

        </div>
    );
};

export default Carta;
