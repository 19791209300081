import React, { useEffect, useState } from 'react';
import { FaEye, FaFlagCheckered, FaPencilAlt, FaRegPlusSquare, FaRegSave, FaRegTrashAlt } from 'react-icons/fa';

const ActionButton = ({ type, action, tooltip = '' }) => {
    const [button, setButton] = useState();
    useEffect(() => {
        switch (type) {
            case 'delete':
                setButton(<FaRegTrashAlt />);
                break;
            case 'edit':
                setButton(<FaPencilAlt />);
                break;
            case 'add':
                setButton(<FaRegPlusSquare />);
                break;
            case 'save':
                setButton(<FaRegSave />);
                break;
            case 'see':
                setButton(<FaEye />);
                break;
            case 'translate':
                setButton(<FaFlagCheckered />);
                break;
            default:
                break;
        }
    }, [type]);

    return (
        <button className={ `action-button ${type}` } onClick={ action } title={ tooltip }>
            { button }
        </button>
    );
};

export default ActionButton;