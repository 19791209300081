import Swal from 'sweetalert2';
import { fetchConToken } from '../helpers/fetch';

export const allMenus = async () => {
	const resp = await fetchConToken(`daymenu`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.daymenus;
	} else {
		Swal.fire('Error', body.msg, 'error');
	}
};

export const getMenuById = async (id) => {
	const resp = await fetchConToken(`daymenu/${id}`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		const daymenu = body.daymenu;
		return daymenu;
	} else {
		Swal.fire('Error', body.msg, 'error');
	}
};

export const createMenu = async (createdMenu) => {
	try {
		const resp = await fetchConToken(`daymenu`, createdMenu, 'POST');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('¡Plato Creado!', 'bien', 'success');
			return true;
		} else {
			Swal.fire('Error al crear plato', body.msg, 'error');
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateMenuById = async (id, updatedMenu) => {
	try {
		const resp = await fetchConToken(`daymenu/${id}`, updatedMenu, 'PUT');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('¡Plato Editado!', 'genial', 'success');
		} else {
			Swal.fire('Error al editar', body.msg, 'error');
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const deleteMenuById = async (id) => {
	const resp = await fetchConToken(`daymenu/${id}`, {}, 'DELETE');
	const body = await resp.json();
	if (body.ok) {
		Swal.fire('¡Plato Borrado!', 'genial', 'success');
	} else {
		Swal.fire('Error al borrar', body.msg, 'error');
	}
};
