import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditorMenu = ({ setQuill, valores = '' }) => {
	const [value, setValue] = useState(valores);
	const quillRef = useRef(null);

	const modules = {
		toolbar: {
			container: [
				[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
				[{ font: [] }],
				[{ 'color': ['#915353'] }, { 'background': ['#915353'] }],
				['bold', 'italic', 'underline', 'strike', 'blockquote'],
				[{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
				['link'],
			],
		},
		clipboard: {
			matchVisual: false,
		},
	};

	useEffect(() => {
		setQuill(value);
	}, [value]);

	return (
		<div className="quill-btn">
			<div className='text-editor'>
				<ReactQuill ref={ quillRef } modules={ modules } formats={ TextEditorMenu.formats } theme='snow' value={ value } onChange={ setValue } />
			</div>
		</div>
	);
};

TextEditorMenu.formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'align', 'color', 'script', 'background'];

export default TextEditorMenu;
