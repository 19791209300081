import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { allMenus, createMenu, deleteMenuById, updateMenuById } from '../../../actions/menu.action';
import useForm from '../../../hooks/useForm';
import MenuElement from '../../_ui/MenuElement/MenuElement';
import SectionTitle from '../../_ui/SectionTitle/SectionTitle';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';
import TextEditorMenu from '../../_ui/TextEditor/TextEditorMenu';

const MenuScreen = () => {
    const isMounted = useRef(true);
    const [menus, setMenus] = useState([]);
    const allMyPosts = async () => {
        const posts = await allMenus();
        setMenus(posts);
    };

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            allMyPosts();
        }
        return () => {
            isMounted.current = false;
        };
    });


    const handleDeletePost = async (id) => {
        await deleteMenuById(id);
        await allMyPosts();
    };


    const askIfDelete = (id) => {
        Swal.fire({
            title: '¿Seguro?',
            text: 'Si borras esto, el menú desaparece',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: '¡Borrar menú!',
            cancelButtonText: '¡Uy, no!',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeletePost(id);
                Swal.fire('¡Plato borrado!', 'Este menú se marchó para no volver', 'success');
            }
        });
    };

    const [isEdit, setIsEdit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { values: menu, setValues, handleInputChange } = useForm();
    const handleCloseCat = () => setShowModal(false);

    const handleOpenCat = (isEd = false) => {
        !isEd && setValues({});
        setIsEdit(isEd);
        setShowModal(true);
    };

    const handleAddDish = async (e) => {
        e.preventDefault();
        menu.price = Number(menu.price);
        menu.firsts = quillFirst;
        menu.seconds = quillSecond;

        let is = isEdit ? await updateMenuById(menu._id, menu) : await createMenu(menu);
        if (is) {
            await allMyPosts();
            handleCloseCat();
            setIsEdit(false);
            setQuillFirst('');
            setQuillSecond('');
        }
    };
    const updateMenu = (id) => {
        let selected = menus.find(menu => menu._id === id);
        console.log(selected);
        setValues(selected);
        handleOpenCat(true);
    };

    const [quillFirst, setQuillFirst] = useState();
    const [quillSecond, setQuillSecond] = useState();

    return (
        <div id="dishes-screen">
            <SectionTitle
                image='https://res.cloudinary.com/bratic-app/image/upload/v1621695842/web/blog_vadvsn.svg'
                title="Menús del día"
                instructions={ `En la web se mostrará aquel en el que coincida la fecha del día con las fechas del menú` }
            />

            {/* <button className="my-btn primary mini" onClick={ () => handleOpenCat(false) }>Añadir menú</button> */ }

            { menus?.length > 0 ? (
                <section className='all-posts'>
                    { menus?.map((elm) => (
                        <MenuElement askIfDelete={ askIfDelete } post={ elm } key={ elm._id } updateMenu={ updateMenu } />
                    )) }
                </section>
            ) : (
                <article className='empty-posts'>No tienes menús creados</article>
            ) }
            <Modal size="lg" centered show={ showModal } onHide={ handleCloseCat }>
                <Modal.Header closeButton>
                    <h4>{ !isEdit ? 'Añadir Menú' : 'Editar Menú' }</h4>
                </Modal.Header>
                <Modal.Body>
                    {
                        isEdit ? (
                            <form className='edit-form' onSubmit={ handleAddDish }>
                                <label>Título del menú</label>
                                <input className='form-input' type="text" name='title' onChange={ handleInputChange } placeholder={ menu.title } />
                                {/* <label htmlFor="start">Fecha inicio menú: { dayjs(menu?.start).format('DD/MM/YYYY') }</label>
                                <input id="start" type='date' name='start' onChange={ handleInputChange } />
                                <label htmlFor="end">Fecha fin menú: { dayjs(menu?.end).format('DD/MM/YYYY') }</label>
                                <input id="end" type='date' name='end' onChange={ handleInputChange } /> */}
                                <label >Primeros</label>
                                <TextEditorMenu setQuill={ setQuillFirst } valores={ menu?.firsts } />
                                <label >Segundos</label>
                                <TextEditorMenu setQuill={ setQuillSecond } valores={ menu?.seconds } />
                                <label>Aclaración</label>
                                <input className='form-input' type="text" name='acclaration' onChange={ handleInputChange } placeholder={ menu.acclaration } />
                                <label>Precio</label>
                                <input className='form-input' type="number" name='price' onChange={ handleInputChange } placeholder={ menu.price } />

                                <button className='my-btn mini right' type='submit'>Editar</button>
                            </form>

                        ) : (

                            <form className='edit-form' onSubmit={ handleAddDish }>
                                <label>Título del menú</label>
                                <input required className='form-input' type="text" name='title' onChange={ handleInputChange } placeholder='Título' />
                                {/* <label htmlFor="start">Fecha inicio menú</label>
                                <input id="start" type='date' name='start' onChange={ handleInputChange } />
                                <label htmlFor="end">Fecha fin menú</label>
                                <input id="end" type='date' name='end' onChange={ handleInputChange } /> */}
                                <label htmlFor="end">Primeros</label>
                                <TextEditorMenu setQuill={ setQuillFirst } />
                                <label htmlFor="end">Segundos</label>
                                <TextEditorMenu setQuill={ setQuillSecond } />
                                <label>Aclaracion</label>
                                <input className='form-input' type="text" name='acclaration' onChange={ handleInputChange } placeholder='Aclaración del precio' />
                                <label>Precio</label>
                                <input required className='form-input' type="number" name='price' onChange={ handleInputChange } placeholder='Precio' />
                                <button className='my-btn mini right' type='submit'>Añadir</button>
                            </form>
                        )
                    }
                </Modal.Body>

            </Modal>

        </div>
    );
};

export default MenuScreen;
