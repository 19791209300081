import React from 'react';
import { alImages } from '../../../actions/dish.action';
import ActionButton from '../ActionButton/ActionButton';

const DishElement = ({ askIfDelete, post, updateDish, translateDish }) => {

    return (
        <>
            <article className='each-dish-prev'>
                <div className='info'>
                    <div className="title-btn">
                        <h5>{ post.language === 'ES' ? '🇪🇸' : '🇬🇧' } - { post.dish } - { post?.price }€</h5>
                        <ActionButton type={ 'delete' } tooltip='Borrar' action={ () => askIfDelete(post._id) } />
                        <ActionButton type={ 'edit' } tooltip='Editar' action={ () => updateDish(post._id) } />
                        { post.language === 'ES' && <ActionButton type={ 'translate' } tooltip="traducir" action={ () => translateDish(post._id) } /> }
                    </div>
                    <p className="status">Descripción del plato: <strong>{ post.description }</strong></p>
                    <p className="status">Categoría del plato: <strong>{ post.category }</strong></p>
                    <p className="status">Estado del plato en carta: <strong>{ post.active ? 'Visible' : 'Oculto' }</strong></p>
                    <div className="alergenos">
                        <p className="status">Alérgenos:</p>
                        <div className="list">
                            {
                                post?.alergenos.map(elm => (<img alt="" src={ alImages(elm) } key={ post?.id + elm } />))
                            }
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
};

export default DishElement;
