import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // load on demand
import ActionButton from '../ActionButton/ActionButton';
dayjs.locale('es');

const MenuElement = ({ askIfDelete, post, updateMenu }) => {

    return (
        <>
            <article className='each-dish-prev'>
                <div className='info'>
                    <div className="title-btn">
                        <h5>{ post?.title }</h5>
                        {/* <ActionButton type={ 'delete' } action={ () => askIfDelete(post._id) } /> */ }
                        <ActionButton type={ 'edit' } action={ () => updateMenu(post._id) } />
                    </div>
                    <p className="status">Precio: <strong>{ post.price }</strong></p>
                </div>
            </article>
        </>
    );
};

export default MenuElement;
