import Swal from 'sweetalert2';
import { fetchConToken } from '../helpers/fetch';

export const allDishes = async () => {
	const resp = await fetchConToken(`dish`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		return body.dishes;
	} else {
		Swal.fire('Error', body.msg, 'error');
	}
};

export const getDishById = async (id) => {
	const resp = await fetchConToken(`dish/${id}`, 'GET');
	const body = await resp.json();
	if (body.ok) {
		const dish = body.dish;
		return dish;
	} else {
		Swal.fire('Error', body.msg, 'error');
	}
};

export const createDish = async (createdDish) => {
	try {
		const resp = await fetchConToken(`dish`, createdDish, 'POST');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('¡Plato Creado!', 'genial', 'success');
			return true;
		} else {
			Swal.fire('Error al crear plato', body.msg, 'error');
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateDishById = async (id, updatedDish) => {
	try {
		const resp = await fetchConToken(`dish/${id}`, updatedDish, 'PUT');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('¡Plato Editado!', 'genial', 'success');
		} else {
			Swal.fire('Error al editar', body.msg, 'error');
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const translateDishById = async (id) => {
	try {
		const resp = await fetchConToken(`dish/translate/${id}`, {}, 'POST');
		const body = await resp.json();
		if (body.ok) {
			Swal.fire('¡Plato traducido!', 'genial', 'success');
		} else {
			Swal.fire('Error al traducir', body.msg, 'error');
		}
		return true;
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const deleteDishById = async (id) => {
	const resp = await fetchConToken(`dish/${id}`, {}, 'DELETE');
	const body = await resp.json();
	if (body.ok) {
		Swal.fire('¡Plato Borrado!', 'genial', 'success');
	} else {
		Swal.fire('Error al borrar', body.msg, 'error');
	}
};

export const alImages = (id) => {
	switch (id) {
		case 'moluscos':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619205/alergenos/moluscos.svg';

		case 'lacteos':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619205/alergenos/lacteos.svg';

		case 'cascara':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619204/alergenos/cascara.svg';

		case 'gluten':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619204/alergenos/gluten.svg';

		case 'huevos':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619204/alergenos/huevos.png';

		case 'soja':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619203/alergenos/soja.svg';

		case 'crustaceos':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619203/alergenos/crustaceos.svg';

		case 'sesamo':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619204/alergenos/sesamo.png';

		case 'pescado':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619202/alergenos/pescado.svg';

		case 'cacahuetes':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619202/alergenos/cacahuetes.svg';

		case 'altramuces':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619202/alergenos/altramuces.svg';

		case 'apio':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619203/alergenos/apio.png';

		case 'mostaza':
			return 'https://res.cloudinary.com/sanroque/image/upload/v1652619202/alergenos/mostaza.svg';

		default:
			break;
	}
};
